import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../../Common/Footer";

//redux
import { Modal, ModalBody, Button, ModalHeader } from "reactstrap";
import axios from "axios";
import Config from "../../Config";
import Alerts from "../../Alerts";
import ReactPaginate from "react-paginate";
import Creditcardedit from "./Creditcardedit";
import Commonheader from "../../Common/Commonheader";
import Basicsettingsheader from "../../Common/Basicsettingsheader";
import Commonimagepath from "../../Commonimagepath";
import Loading from "../../Loading";

function Creditcard() {


  const[credit_card,setCredit_card]=useState({
    title:"",
    image:[]
  })
  const [modal_animationFlip, setmodal_animationFlip] = useState(false);
  function tog_animationFlip() {
    setmodal_animationFlip(!modal_animationFlip);
  }
  const [modal_backdrop, setmodal_backdrop] = useState(false);
  const [pageid, setPageid] = useState("");

  const [List, setList] = useState([]);
  const [alert, setAlert] = useState(false);
  const [alertmessage, setAlertmessage] = useState("");
  const [alerttype, setAlerttype] = useState("");
  const [formerror, setError] = useState({});
  const [open, setOpen] = useState(false);
  const [paginate, setPaginate] = useState([]);
  const [creditdetail,setCreditdetail]=useState([]);
  const [creditimage,setCreditImage]=useState([]);
  const [page,setPage]=useState(1);
  const [pageindex,setPageindex]=useState(1);
  const [loader,setLoader] = useState(false);

  function tog_backdrop(ids) {
    setmodal_backdrop(!modal_backdrop);
    setPageid(ids);
    const getinfo = async (e) => {
      const res = await fetch(`${Config.apiurl}admin/credit-card/${ids}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      const data1 = await res.json();
      const data = data1.data;
      if (data1.status_code !== "200" || !data) {
        console.log("error");
      } else {
        setCreditdetail(data);
      }
    };
    getinfo();
  }

  const getData = async (e) => {
    const res = await fetch(`${Config.apiurl}admin/credit-card/list?page=1`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    const data1 = await res.json();
    const data = data1.data;

    if (data1.status_code !== "200" || !data) {
      console.log("error");
    } else {
      setList(data.data);
      setPaginate(data.last_page);
      setLoader(true);
    }
  };
 

  let name, value;
  const handleInput = (e) => {
    e.preventDefault();
    console.log(e);
    name = e.target.name;
    value = e.target.value;
    setCredit_card({ ...credit_card, [name]: value });
  };
  const onFileChange = (e) => {
    console.log(e);
    let name = e.target.name;
    setCredit_card({ ...credit_card, [name]: e.target.files[0] });
  };




  const handleInputedit = (e) => {
    e.preventDefault();
    console.log(e);
    name = e.target.name;
    value = e.target.value;
    setCreditdetail({ ...creditdetail, [name]: value });
  };
  const onFileChangeedit = (e) => {
    console.log(e);
    let name = e.target.name;
    setCreditImage({ ...creditimage, [name]: e.target.files[0] });
  };
  function Reset(){
    setCredit_card({
      title:"",
      image:""
    })
  }

  const validate = (values) => {
    const errors = {};
    if (!values.title) {
      errors.title = "Name is required!";
    }
    if (!values.image) {
      errors.image = "Image is required!";
    }
    else{
      if(
        values.image.type === "image/jpeg" ||
        values.image.type === "image/jpg" ||
        values.image.type === "image/png" ||
        values.image.type === "image/webp" 
      ){
      console.log("");
    } else {  
      errors.image = "Only support jpg, jpeg, png, webp image!";
    }
  }
   
    return errors;
  };

  const PostData = async (e) => {
    e.preventDefault();
    const ress = validate(credit_card);
    setError(ress);
    if (
      !ress.title &&
      !ress.image
     
    ) {
      const formData = new FormData();
      formData.append("title", credit_card.title);
      formData.append("image", credit_card.image);
      
      axios
        .post(`${Config.apiurl}admin/credit-card/add`, formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          if (res.status_code !== "200" || !res) {
            setAlert(true);
            setAlertmessage(res.message);
            setOpen(true);
            setAlerttype("error");
          } else {
            setAlert(true);
            setAlertmessage(res.message);
            setOpen(true);
            setAlerttype("success");
            Reset();
            
             handlePageClick(page);

          }
        });
    }
  };
  const fetchComments = async (currentPage) => {
    const res = await fetch(`${Config.apiurl}admin/credit-card/list?page=1`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
    );

    const data = await res.json();
    return data.data.data;
  };


  const handlePageClick = async (data) => {
    if(data===1){
      setPage(data);
      setPageindex(1);
      const commentsFormServer = await fetchComments(data);
    
      setList(commentsFormServer);
    
    }
    else{
      let currentPage = data.selected + 1;
      setPageindex(currentPage);
      setPage(data);
      const commentsFormServer = await fetchComments(currentPage);
    
      setList(commentsFormServer);
    }
  };


  const UpdateData = async (id) => {
    const formData = new FormData();
    formData.append("title", creditdetail.title);
    if(creditimage.image===undefined)
    {
      formData.append("image", "");

    }
    else{
      formData.append("image", creditimage.image);

    }
    formData.append("is_active", creditdetail.status);

    axios
      .post(`${Config.apiurl}admin/credit-card/update/${id}`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.status_code !== "200" || !res) {
          setAlert(true);
          setAlertmessage(res.message);
          setOpen(true);
          setAlerttype("error");
          setmodal_animationFlip(false);
        } else {
          setAlert(true);
          setAlertmessage(res.message);
          setOpen(true);
          setAlerttype("success");
          setmodal_animationFlip(false);
          setmodal_backdrop(false);
       
          handlePageClick(page);

        }
      });
  };
  useEffect(() => {
    getData();
  }, []);

  console.log(List);
  return (
    <div id="layout-wrapper">
    <Basicsettingsheader/>

      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="db-page-title">Credit card</h4>
                  {/* <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Dashboard</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="#">Settings</Link>
                      </li>
                      <li className="breadcrumb-item ">
                        <Link to="#">Basic Settings</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="#">Payment Settings</Link>
                      </li>
                      <li className="breadcrumb-item active">Credit Card</li>
                    </ol>
                  </div> */}
                </div>
              </div>
            </div>
            {alert ? (
              <Alerts type={alerttype} message={alertmessage} open={open} />
            ) : (
              ""
            )}
            <div className="row project-wrapper">
              <div className="row dashboard-top-tab-style">
                <div className="col-xxl-12 dashboard-main-style">
                  <div className="card">
                    <div className="card-header align-items-center d-flex">
                      <h4 className="card-title mb-0 flex-grow-1">
                        Create Page
                      </h4>
                      <div className="flex-shrink-0">
                        <button
                          type="button"
                          className="btn db-cancel-button"
                          data-bs-toggle="offcanvas"
                          to=""
                          onClick={Reset}
                        >
                          Reset
                        </button>
                        <button
                          type="button"
                          className="btn db-save-button"
                          data-bs-toggle="modal"
                          id="create-btn"
                          data-bs-target=""
                          onClick={PostData}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="live-preview">
                        <form action="#">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label
                                  for="firstNameinput"
                                  className="form-label"
                                >
                                  Title{" "}
                                  <span className="form-validation-required">
                                    *
                                  </span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder=""
                                 name="title"
                                 value={credit_card.title}
                                 onChange={handleInput}
                                />
                                 <label
                                  style={{ color: "red", marginTop: "3px" }}
                                >
                                  {formerror.title}
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label
                                  for="lastNameinput"
                                  className="form-label"
                                >
                                  Image
                                  <span className="form-validation-required">
                                    *
                                  </span>
                                </label>
                                <input
                                  type="file"
                                  className="form-control"
                                  placeholder=""
                                  id="lastNameinput"
                                  name="image"
                                  onChange={onFileChange}
                                  accept=".jpg, .jpeg, .webp, .png"
                                />
                                 <label
                                  style={{ color: "red", marginTop: "3px" }}
                                >
                                  {formerror.image}
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="padding-20"></div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="card" id="orderList">
                    <div className="card-header border-0">
                      <div className="row align-items-center gy-3">
                        <div className="col-sm d-flex justify-content-between">
                          <h5 className="card-title mb-0">List Of Cards</h5>
                          
                        </div>
                      </div>
                    </div>

                    <div className="card-body pt-0">
                      <div>
                        <ul
                          className="nav nav-tabs nav-tabs-custom nav-success mb-3"
                          role="tablist"
                        ></ul>

                        <div className="table-responsive table-card mb-1">
                          <table
                            className="table table-nowrap align-middle"
                            id="orderTable"
                          >
                           
                           <thead className="text-muted table-light">
                              <tr className="text-uppercase">
                                <th className="" data-sort="id">
                                  Sl No.
                                </th>
                                <th className="" data-sort="customer_name">
                                  Card
                                </th>
                                <th className="" data-sort="customer_name">
                                Image
                                </th>
                                {/* <th className="" data-sort="customer_name">
                                  Status
                                </th> */}
                                <th className="" data-sort="city">
                                  Action
                                </th>
                              </tr>
                            </thead>
                            {loader===false?
                             <tbody>
                             <tr>
                            <td
                              style={{ textAlign: "center" }}
                              colSpan={8}
                            >
                              <Loading />
                            </td>
                          </tr>
                          </tbody>
                          :
                            <tbody className="list form-check-all">
                              {List.map((user, index) => {
                                return (
                                  <tr
                                    key={user.id}
                                    className={user.selected ? "selected" : ""}
                                  >
                                  
                                    <td className="id">{pageindex===1 ? index+1: (pageindex-1) * 10 + index +1}</td>
                                    <td className="customer_name">
                                      {user.title}
                                    </td>
                                    <td className="customer_name">
                                      <img src={`${Commonimagepath.apiurl}${user.image}`}
                                      alt="" />
                                    </td>
                                    <td>
                                      <ul className="list-inline hstack gap-2 mb-1">
                                        <li
                                          className="list-inline-item edit"
                                          data-bs-toggle="tooltip"
                                          data-bs-trigger="hover"
                                          data-bs-placement="top"
                                          title="Edit"
                                        >
                                          <Link
                                            to="#"
                                            onClick={() =>
                                              tog_backdrop(user.card_id)
                                            }
                                            data-bs-toggle="modal"
                                            className="text-primary d-inline-block edit-item-btn"
                                          >
                                            <i className="ri-pencil-fill fs-16"></i>
                                          </Link>
                                        </li>
                                        <li
                                          className="list-inline-item"
                                          data-bs-toggle="tooltip"
                                          data-bs-trigger="hover"
                                          data-bs-placement="top"
                                          title="Remove"
                                        >
                                          {/* <Link
                                            className="text-danger d-inline-block remove-item-btn"
                                            data-bs-toggle="modal"
                                            to="#"
                                            onClick={() => tog_animationFlip()}
                                          >
                                            <i className="ri-delete-bin-5-fill fs-16"></i>
                                          </Link> */}
                                        </li>
                                      </ul>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                           }
                          </table>
                        </div>
                        {paginate > 1 ? (
                            <ReactPaginate
                              previousLabel={"Previous"}
                              nextLabel={"Next"}
                              breakLabel={"..."}
                              pageCount={paginate}
                              marginPagesDisplayed={1}
                              pageRangeDisplayed={1}
                              onPageChange={handlePageClick}
                              containerClassName={
                                "pagination justify-content-end"
                              }
                              pageClassName={"page-item"}
                              pageLinkClassName={"page-link"}
                              previousClassName={"page-item"}
                              previousLinkClassName={"page-link"}
                              nextClassName={"page-item"}
                              nextLinkClassName={"page-link"}
                              breakClassName={"page-item"}
                              breakLinkClassName={"page-link"}
                              activeClassName={"active"}
                            />
                          ) : (
                            ""
                          )}
                      </div>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
      {modal_backdrop ? (
          <Creditcardedit
            pagedata={creditdetail}          
            modal_backdrop={modal_backdrop}
            tog_backdrop={tog_backdrop}
            pageid={pageid}
            UpdateData={UpdateData}
            handleInputedit={handleInputedit}
            onFileChangeedit={onFileChangeedit}
          />
        ) : (
          ""
        )}
    </div>
  );
}

export default Creditcard;

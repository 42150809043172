import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Modal, ModalBody } from "reactstrap";
import Footer from "../../Common/Footer";
import Privatesaleheader from "../../Common/Privatesaleheader";
import Toastmodal from "../../Common/Toastmodal";
import { useAuthHandling } from "../../Common/useAuthHandling";
import Commonimagepath from "../../Commonimagepath";
import Config from "../../Config";
import Loading from "../../Loading";
import Select from "react-select";
import { UserContext } from "../../Contexts/UserContext";
import { MultiSelect } from "react-multi-select-component";

function Upselling() {
  const { isAuthorized, handleUnauthorized } = useAuthHandling();
  const { updateHotel_select, hotel_select, hotel_selectid } =
    useContext(UserContext);
  const [id, setId] = useState(hotel_select ? hotel_select[0]?.value : "");
  const [hotelname, setHotelname] = useState(
    hotel_select ? hotel_select[0]?.label : ""
  );
  let navigate = useNavigate();
  const [tab, setTab] = useState("room_upsell");
  const [rooms, setRooms] = useState([]);
  const [upsell, setUpsell] = useState([]);
  const [selectedUpsell, setSelectedUpsell] = useState([]);
  const [toastmodal, setToastmodal] = useState(false);
  const [toastmessage, setToastmessage] = useState("");
  const [toasttype, setToasttype] = useState("");
  const [toastlarge, setToastlarge] = useState(false);
  const [list, setList] = useState({ status: true });
  const [loading, setLoading] = useState(false);
  const [modal_animationFlip, setmodal_animationFlip] = useState(false);
  const [modal_animationFlip1, setmodal_animationFlip1] = useState(false);
  const [modal_animationFlip2, setmodal_animationFlip2] = useState(false);
  const [modal_animationFlip3, setmodal_animationFlip3] = useState(false);
  const [loop, setLoop] = useState("");
  const [loopboard, setLoopboard] = useState("");
  const [loopextra, setLoopextra] = useState("");
  const [loopextra1, setLoopextra1] = useState("");
  const [roomdata, setRoomdata] = useState([]);
  const [formerror, setError] = useState({});
  const [formerror1, setError1] = useState([{}]);
  const [formerror2, setError2] = useState([{}]);

  const [days, setDays] = useState("");
  const [selectedBoardUpsell, setSelectedBoardUpsell] = useState([]);
  const [boards, setBoards] = useState([]);
  const [extras, setExtras] = useState([]);
  const [currency, setCurrency] = useState("");
  const [upsellBoards, setUpsellBoards] = useState([]);
  const [boardData, setBoardData] = useState([]);
  const [extradata, setExtradata] = useState([]);
  const [extrasnew, setExtrasnew] = useState([]);
  const [selectedExtraUpsell, setSelectedExtraUpsell] = useState([]);
  const [selectedExtraUpsell1, setSelectedExtraUpsell1] = useState([]);


  function tog_animationFlip(i) {
    setLoop(i);
    setmodal_animationFlip(!modal_animationFlip);
  }

  function tog_animationFlip1(i) {
    setLoopboard(i);
    setmodal_animationFlip1(!modal_animationFlip1);
  }

  function tog_animationFlip2(i) {
    setLoopextra(i);
    setmodal_animationFlip2(!modal_animationFlip2);
  }

  function tog_animationFlip3(i) {
    setLoopextra1(i);
    setmodal_animationFlip3(!modal_animationFlip3);
  }

  const handleloyaltyToggle = (isChecked, name) => {
    setList({ ...list, [name]: isChecked });
  };

  const handleConditionChange = (name, value) => {
    const updatedValues = { ...list, [name]: value };
    setList(updatedValues);
  };

  const handleUpsellChange = (val) => {
    const updatedValues = { ...selectedUpsell };
    updatedValues[loop] = val;
    setSelectedUpsell(updatedValues);
    tog_animationFlip();
  };

  const handleUpsellChangeExtra = (val) => {
    const updatedSelectedExtraUpsell = [...selectedExtraUpsell];
    updatedSelectedExtraUpsell[loopextra] = val; 
    setSelectedExtraUpsell(updatedSelectedExtraUpsell);
  };

  const handleUpsellChangeExtra1 = (val) => {
    const updatedSelectedExtraUpsell1 = [...selectedExtraUpsell1];
    updatedSelectedExtraUpsell1[loopextra1] = val; 
    setSelectedExtraUpsell1(updatedSelectedExtraUpsell1);
  };

  const handleUpsellBoardChange = (val) => {
    const updatedValues = { ...selectedBoardUpsell };
    updatedValues[loopboard] = val;
    setSelectedBoardUpsell(updatedValues);
    tog_animationFlip1();
  };

  const handleToggle = (isChecked, name, index) => {
    const updatedExtraList = [...rooms];
    updatedExtraList[index][name] = isChecked;
    setRooms(updatedExtraList);
  };

  const handleDropdownChange = (newType, index) => {
    const updatedRooms = [...rooms];
    updatedRooms[index].type = newType;
    setRooms(updatedRooms); 
  };

  const handleRoomInfoChange = (name, index,val) => {
    const updatedRooms = [...rooms];
    updatedRooms[index][name] = val;
    setRooms(updatedRooms); 
  };

  const handleToggleBoard = (isChecked, name, index) => {
    const updatedExtraList = [...boards];
    updatedExtraList[index][name] = isChecked;
    setBoards(updatedExtraList);
  };

  const handleRoomInfoChangeBoard = (name, index,val) => {
    const updatedRooms = [...boards];
    updatedRooms[index][name] = val;
    setBoards(updatedRooms); 
  };

  const getList = async (e) => {
    const res = await fetch(
      `${Config.apiurl}hotel/private-sale/upselling/all-list`,
      {
        method: "POST",
        body: JSON.stringify({
          hotel_id: id,
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code === 200) {
        setRooms(data.data.hotel_rooms);
        setUpsell(data.data.upsell_rooms);
        setBoards(data.data.boards);
        setExtrasnew(data.data.extras);
        setCurrency(data.data.currency);
        setUpsellBoards(data.data.upsell_boards);
        setLoading(true);
      } else if (data.reauth === true) {
        handleUnauthorized();
        getList();
      } else {
        console.log("error");
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      getList();
    } else {
      console.log("error");
    }
  };

  // const getRooms = async (e) => {
  //   const res = await fetch(`${Config.apiurl}hotel/active-rooms/${id}`, {
  //     method: "POST",
  //     headers: {
  //       Authorization: `Bearer ${localStorage.getItem("token")}`,
  //     },
  //   });
  //   if (res.status === 200) {
  //     const data = await res.json();
  //     if (data.status_code === "200") {
  //       setRooms(data.data);
  //       setLoading(true);
  //     } else if (data.reauth === true) {
  //       handleUnauthorized();
  //       getRooms();
  //     } else {
  //       console.log("error");
  //     }
  //   } else if (res.status === 401) {
  //     handleUnauthorized();
  //     getRooms();
  //   } else {
  //     console.log("error");
  //   }
  // };

  // const getUpsells = async (e) => {
  //   const res = await fetch(
  //     `${Config.apiurl}hotel/upselling/active-rooms/${id}`,
  //     {
  //       method: "POST",
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem("token")}`,
  //       },
  //     }
  //   );
  //   if (res.status === 200) {
  //     const data = await res.json();
  //     if (data.status_code === "200") {
  //       setUpsell(data.data);
  //       setLoading(true);
  //     } else if (data.reauth === true) {
  //       handleUnauthorized();
  //       getUpsells();
  //     } else {
  //       console.log("error");
  //     }
  //   } else if (res.status === 401) {
  //     handleUnauthorized();
  //     getUpsells();
  //   } else {
  //     console.log("error");
  //   }
  // };

  const getData = async (e) => {
    const res = await fetch(
      `${Config.apiurl}hotel/private-sale/upselling/view`,
      {
        method: "POST",
        body: JSON.stringify({
          hotel_id: id,
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code === "200") {
        if (data.data.length !== 0) {
          setList(data.data?.upselling);
          setRoomdata(data.data?.room_data);
          setDays(data.data?.upselling_days);
          setBoardData(data.data.meal_plan);
          setExtradata(data.data.addons);
        }
      } else if (data.reauth === true) {
        handleUnauthorized();
        getData();
      } else {
        console.log("error");
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      getData();
    } else {
      console.log("error");
    }
  };

  const Add = async () => {
    let isFormValid = true;
    const errors = {};
    let roomarray = [];
    let boardarray = [];

    if (!list.cancel_penalty_type) {
      isFormValid = false;
      errors.cancel_penalty_type = "This field is required!";
    }
    if (!list.prepayment_type) {
      isFormValid = false;
      errors.prepayment_type = "This field is required!";
    }
    if (!list.member_type) {
      isFormValid = false;
      errors.member_type = "This field is required!";
    }

    // for (let i = 0; i < rooms.length; i++) {
    //   let err = {};
    //   if (!rooms[i].rate) {
    //     isFormValid = false;
    //     err.rate = "Rate is required!";
    //   }
    //   if (!rooms[i].promotion_title) {
    //     isFormValid = false;

    //     err.promotion_title = "Promotion title is required!";
    //   }
    //   roomarray.push(err);
    // }

    // for (let i = 0; i < boards.length; i++) {
    //   let err = {};
    //   if (!boards[i].rate) {
    //     isFormValid = false;
    //     err.rate = "Rate is required!";
    //   }
    //   boardarray.push(err);
    // }

    setError(errors);
    // setError1(roomarray);
    // setError2(boardarray);


    if (isFormValid === true) {
      let newRoom = [];
      for (let i = 0; i < rooms.length; i++) {
        if (selectedUpsell[i]?.value) {
          let multiarray = {
            upsell_room: selectedUpsell[i].value,
            room: rooms[i].value,
            pre_stay_status: rooms[i].pre_stay_status?rooms[i].pre_stay_status:false,
            confirm_mail_status: rooms[i].confirm_mail_status?rooms[i].confirm_mail_status:false,
            type: rooms[i].type?rooms[i].type:"currency",
            rate: rooms[i].rate,
            promotion_title: rooms[i].promotion_title,
          };
          newRoom.push(multiarray);
        }
      }

      let newBoard = [];
      for (let i = 0; i < boards.length; i++) {
        if (selectedBoardUpsell[i]?.value) {
          let multiarray = {
            offer_board: selectedBoardUpsell[i].value,
            board: boards[i].value,
            pre_stay_status: boards[i].pre_stay_status?boards[i].pre_stay_status:false,
            confirm_mail_status: boards[i].confirm_mail_status?boards[i].confirm_mail_status:false,
            offer_rate: boards[i].rate,
          };
          newBoard.push(multiarray);
        }
      }

      let newAddon = [];
      for (let i = 0; i < rooms.length; i++) {
        if (selectedExtraUpsell[i]?.length || selectedExtraUpsell1[i]?.length) {
          let multiarray = {
            room: rooms[i].value,
            pre_stay_extras: selectedExtraUpsell1[i].map(extra => extra.value), 
            confirmation_extras: selectedExtraUpsell[i].map(extra => extra.value) 
          };
      
          newAddon.push(multiarray);
        }
      }

      const res = await fetch(
        `${Config.apiurl}hotel/private-sale/upselling/create`,
        {
          method: "POST",
          body: JSON.stringify({
            hotel_id: id,
            status: list.status,
            cancel_penalty_type: list.cancel_penalty_type,
            prepayment_type: list.prepayment_type,
            member_type: list.member_type,
            upsell_rooms: newRoom,
            send_days_before: days,
            upsell_board:newBoard,
            upsell_addons:newAddon
          }),
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      );
      if (res.status === 200) {
        const data = await res.json();
        if (data.status_code !== "200" || !data.data) {
          console.log("error");
          setToastmodal(true);
          setToastmessage(data.message);
          setToasttype("error");
          setToastlarge(true);
        } else {
          setToastmodal(true);
          setToastmessage(data.message);
          setToasttype("success");
          setToastlarge(true);
          getData();
        }
      } else if (res.status === 401) {
        handleUnauthorized();
        Add();
      } else {
        console.log("error");
      }
    }
  };

  const Update = async () => {
    let isFormValid = true;
    const errors = {};

    if (!list.cancel_penalty_type) {
      isFormValid = false;
      errors.cancel_penalty_type = "This field is required!";
    }
    if (!list.prepayment_type) {
      isFormValid = false;
      errors.prepayment_type = "This field is required!";
    }
    if (!list.member_type) {
      isFormValid = false;
      errors.member_type = "This field is required!";
    }
    if (!days) {
      isFormValid = false;
      errors.days = "This field is required!";
    }
    setError(errors);

    if (isFormValid === true) {
      let newRoom = [];
      for (let i = 0; i < rooms.length; i++) {
        if (selectedUpsell[i]?.value) {
          let multiarray = {
            upsell_room: selectedUpsell[i].value,
            room: rooms[i].value,
            pre_stay_status: rooms[i].pre_stay_status,
            confirm_mail_status: rooms[i].confirm_mail_status,
            type: rooms[i].type,
            rate: rooms[i].rate,
            promotion_title: rooms[i].promotion_title,
          };
          newRoom.push(multiarray);
        }
      }

      let newBoard = [];
      for (let i = 0; i < boards.length; i++) {
        if (selectedBoardUpsell[i]?.value) {
          let multiarray = {
            offer_board: selectedBoardUpsell[i].value,
            board: boards[i].value,
            pre_stay_status: boards[i].pre_stay_status,
            confirm_mail_status: boards[i].confirm_mail_status,
            offer_rate: boards[i].rate,
          };
          newBoard.push(multiarray);
        }
      }

      let newAddon = [];
      for (let i = 0; i < rooms.length; i++) {
        if (selectedExtraUpsell[i]?.length || selectedExtraUpsell1[i]?.length) {
          let multiarray = {
            room: rooms[i].value,
            pre_stay_extras: selectedExtraUpsell1[i].map(extra => extra.value), 
            confirmation_extras: selectedExtraUpsell[i].map(extra => extra.value) 
          };
      
          newAddon.push(multiarray);
        }
      }

      const res = await fetch(
        `${Config.apiurl}hotel/private-sale/upselling/update`,
        {
          method: "POST",
          body: JSON.stringify({
            hotel_id: id,
            upsell_id: list.upsell_id,
            status: list.status,
            cancel_penalty_type: list.cancel_penalty_type,
            prepayment_type: list.prepayment_type,
            member_type: list.member_type,
            upsell_rooms: newRoom,
            send_days_before: days,
            upsell_board:newBoard,
            upsell_addons:newAddon
          }),
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      );
      if (res.status === 200) {
        const data = await res.json();
        if (data.status_code !== "200" || !data.data) {
          console.log("error");
          setToastmodal(true);
          setToastmessage(data.message);
          setToasttype("error");
          setToastlarge(true);
        } else {
          setToastmodal(true);
          setToastmessage(data.message);
          setToasttype("success");
          setToastlarge(true);
        }
      } else if (res.status === 401) {
        handleUnauthorized();
        Update();
      } else {
        console.log("error");
      }
    }
  };

  useEffect(() => {

    // getRooms();
    // getUpsells();
    getData();
    getList();
 
  }, []);

  useEffect(() => {
    let newUpsell;
    const updatedRooms = [...rooms]; 
  
    for (let i = 0; i < rooms?.length; i++) {
      for (let j = 0; j < roomdata?.length; j++) {
        if (roomdata[j]?.room === rooms[i]?.value) {
          newUpsell = roomdata[j]?.upsell_room?.value;
  
          for (let k = 0; k < upsell?.length; k++) {
            if (newUpsell === upsell[k]?.value) {
              selectedUpsell[i] = {
                value: upsell[k]?.value,
                label: upsell[k]?.label,
                room_photo: upsell[k]?.room_photo,
              };
            }
          }
  
          updatedRooms[i] = {
            ...updatedRooms[i], 
            pre_stay_status: roomdata[j]?.pre_stay_status,
            confirm_mail_status: roomdata[j]?.confirm_mail_status,
            type: roomdata[j]?.type?roomdata[j]?.type:"currency",
            rate: roomdata[j]?.rate,
            promotion_title: roomdata[j]?.promotion_title,
          };
        }
      }
    }
  
    setRooms(updatedRooms); 
  
  }, [rooms?.length, roomdata?.length, upsell?.length, id]);

  useEffect(() => {
    let newBoardUpsell;
    const updatedRooms = [...boards]; 
  
    for (let i = 0; i < boards?.length; i++) {
      for (let j = 0; j < boardData?.length; j++) {
        if (boardData[j]?.board === boards[i]?.value) {
          newBoardUpsell = boardData[j]?.upsell_board?.value;
  
          for (let k = 0; k < upsellBoards?.length; k++) {
            if (newBoardUpsell === upsellBoards[k]?.value) {
              selectedBoardUpsell[i] = {
                value: upsellBoards[k]?.value,
                label: upsellBoards[k]?.label,
              };
            }
          }
  
          updatedRooms[i] = {
            ...updatedRooms[i], 
            pre_stay_status: boardData[j]?.pre_stay_status,
            confirm_mail_status: boardData[j]?.confirm_mail_status,
            rate: boardData[j]?.rate,
          };
        }
      }
    }
  
    setBoards(updatedRooms); 
  
  }, [boards?.length, boardData?.length, upsellBoards?.length, id]);

  useEffect(() => {
    if (extradata && extradata.length > 0 && extras && extras.length > 0) {
      const updatedExtraUpsell = [];
      const updatedExtraUpsell1 = [];
  
      rooms.forEach((room, i) => {
        const roomData = extradata.find(item => item.room === room.value);
  
        if (roomData) {
          const confirmationExtras = roomData.confirmation_extras.map(extra => {
            const matchingExtra = extras.find(e => e.value === extra.value);
            return {
              label: extra.label,
              value: extra.value,
              image: matchingExtra?.image || "" 
            };
          });
  
          const preStayExtras = roomData.pre_stay_extras.map(extra => {
            const matchingExtra = extras.find(e => e.value === extra.value);
            return {
              label: extra.label,
              value: extra.value,
              image: matchingExtra?.image || "" 
            };
          });
  
          updatedExtraUpsell[i] = confirmationExtras;
          updatedExtraUpsell1[i] = preStayExtras;
        }
      });
  
      setSelectedExtraUpsell(updatedExtraUpsell);
      setSelectedExtraUpsell1(updatedExtraUpsell1);
    }
  }, [extradata, rooms, extras]);

  useEffect(() => {
    if (extrasnew) {
      const formattedExtras = extrasnew.map(item => ({
        value: item.extra_id,
        label: item.title,
        image: item.image,
      }));
  
      setExtras([...formattedExtras]); 
    }
  }, [extrasnew]);
  

console.log(extras);

  useEffect(() => {
    setId(hotel_select[0]?.value);
  }, [hotel_select]);

  useEffect(() => {
    setHotelname(hotel_select[0]?.label);
  }, [hotel_select]);

console.log(selectedExtraUpsell1);
console.log(selectedExtraUpsell);
console.log(selectedUpsell);
console.log(rooms);
console.log(boards);

  return (
    <div>
      <Privatesaleheader />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <h4 className="" style={{ marginLeft: "10px" }}>
              {hotelname}
            </h4>
            <div className="row project-wrapper">
              <div className="row dashboard-top-tab-style">
                <div className="col-xxl-12 dashboard-main-style">
                  {loading === false ? (
                    <Loading />
                  ) : (
                    <div className="card">
                      <div className="card-header align-items-center d-flex">
                        {/* <h4 className="card-title mb-0 flex-grow-1">
                          UPSELLING
                        </h4> */}
                         <ul className="offer-create-list_rate" style={{border:"none"}}>
                          <li
                            className={
                              tab === "room_upsell"
                                ? "extra-step-list  steplist-two active"
                                : "extra-step-list steplist-two"
                            }
                            onClick={(e) => setTab("room_upsell")}
                          >
                            Room Upsell
                          </li>
                          <li
                            className={
                              tab === "board_upsell"
                                ? "extra-step-list  steplist-two active"
                                : "extra-step-list steplist-two"
                            }
                            onClick={(e) => setTab("board_upsell")}
                            style={{
                              borderLeft: "1px solid #d2d2d2",
                              borderRight: "1px solid #d2d2d2",
                            }}
                          >
                          Board Upsell
                          </li>
                          <li
                            className={
                              tab === "addon_upsell"
                                ? "extra-step-list  steplist-two active"
                                : "extra-step-list steplist-two"
                            }
                            onClick={(e) => setTab("addon_upsell")}
                            style={{
                              borderLeft: "1px solid #d2d2d2",
                              borderRight: "1px solid #d2d2d2",
                            }}
                          >
                          Add-on Upsell
                          </li>
                        </ul>
                        <div className="flex-shrink-0">
                          <div
                            className="form-check form-switch form-switch-danger form-check-inline condition-active-status"
                            dir="ltr"
                          >
                            <label
                              className="form-check-label"
                              htmlFor="inlineswitch5"
                              style={{
                                marginRight: "40px",
                                marginTop: "5px",
                                marginLeft: "5px",
                                color: "black",
                              }}
                            >
                              Active
                            </label>
                            <input
                              type="checkbox"
                              className="form-check-input offer_checkbox1"
                              id="inlineswitch5"
                              value={list?.status}
                              checked={list?.status === true ? true : false}
                              onChange={(e) =>
                                handleloyaltyToggle(e.target.checked, "status")
                              }
                              style={{ width: "50px", height: "25px" }}
                            />
                          </div>
                          {list?.upsell_id ? (
                            <button
                              type="button"
                              className="btn db-save-button"
                              data-bs-toggle="modal"
                              onClick={Update}
                            >
                              Save
                            </button>
                          ) : (
                            <button
                              type="button"
                              className="btn db-save-button"
                              data-bs-toggle="modal"
                              onClick={Add}
                            >
                              Save
                            </button>
                          )}
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="live-preview">
                          <form action="#">
                            <div className="" style={{ marginTop: "15px" }}>
                              <div className="row">
                                <div>
                                  Enable this feature to ensure your clients
                                  receive an email{" "}
                                  <input
                                    type="number"
                                    value={days}
                                    onChange={(e) => setDays(e.target.value)}
                                    style={{
                                      width: "55px",
                                      border: "1px solid #d2d2d2",
                                      textAlign: "center",
                                    }}
                                    onFocus={(e) =>
                                      e.target.addEventListener(
                                        "wheel",
                                        function (e) {
                                          e.preventDefault();
                                        },
                                        { passive: false }
                                      )
                                    }
                                  />{" "}
                                  <label
                                    style={{
                                      color: "red",
                                      position: "absolute",
                                      top: "28px",
                                      left: "29%",
                                    }}
                                  >
                                    {formerror.days}
                                  </label>
                                  days prior to their arrival, providing them
                                  with the opportunity to upgrade their room or
                                  apartment to a superior option.
                                </div>
                                {tab==="room_upsell"?
                                <>
                                {rooms.map((data, i) => {
                                  return (
                                    <>
                                      <div
                                        key={i}
                                        style={{
                                          border: "1px solid #f1f1f1",
                                          marginTop: "2%"}}
                                      >
                                        <div className="d-flex" style={{justifyContent:"space-between"}}>
                                        <div className="room-style-upselling">
                                          {data.room_photo ? (
                                            <div
                                              className=""
                                              style={{ float: "left" }}
                                            >
                                              <img
                                                src={`${Commonimagepath.apiurl}${data.room_photo}`}
                                                alt=""
                                                className="position-absolute"
                                                style={{
                                                  width: "35px",
                                                  height: "30px",
                                                  bottom: "10px",
                                                }}
                                              />
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                          <span style={{ paddingLeft: "15%" }}>
                                            {data.label}
                                          </span>
                                        </div>
                                        <img
                                          src="/images/icon/upsell.jpeg"
                                          style={{
                                            height: "50px",
                                            marginTop: "20px",
                                          }}
                                        />
                                        <div
                                          className={
                                            selectedUpsell[i]?.value
                                              ? "room-style-upselling-new"
                                              : "room-style-upselling-new text-center"
                                          }
                                        >
                                          {selectedUpsell[i]?.value ? (
                                            <>
                                              {selectedUpsell[i]?.room_photo ? (
                                                <div
                                                  className=""
                                                  style={{ float: "left" }}
                                                >
                                                  <img
                                                    src={`${Commonimagepath.apiurl}${selectedUpsell[i]?.room_photo}`}
                                                    alt=""
                                                    className="position-absolute"
                                                    style={{
                                                      width: "35px",
                                                      height: "30px",
                                                      bottom: "10px",
                                                    }}
                                                  />
                                                </div>
                                              ) : (
                                                ""
                                              )}
                                              <span
                                                style={{ paddingLeft: "15%" }}
                                              >
                                                {selectedUpsell[i]?.label}
                                              </span>
                                              <span
                                                className="bx bxs-chevron-down"
                                                style={{
                                                  float: "right",
                                                  marginTop: "5px",
                                                  cursor: "pointer",
                                                }}
                                                onClick={() =>
                                                  tog_animationFlip(i)
                                                }
                                              ></span>
                                            </>
                                          ) : (
                                            <span>
                                              <Link
                                                className="extra-rate-add"
                                                to="#"
                                                onClick={() =>
                                                  tog_animationFlip(i)
                                                }
                                              >
                                                +
                                              </Link>
                                            </span>
                                          )}
                                        </div>
                                        <div style={{marginTop:"20px"}}>
                                        <input
                                        className="form-check-input offer_include_check"
                                        type="checkbox"
                                        id="formCheck1"
                                        value={data.pre_stay_status}
                                        checked={
                                          data.pre_stay_status === true
                                            ? true
                                            : false
                                        }
                                        onChange={(e) =>
                                          handleToggle(
                                            e.target.checked,
                                            "pre_stay_status",
                                            i,
                                          )
                                        }
                                      />
                                      <label style={{ marginLeft: "5px" }}>
                                        Pre-Stay
                                      </label>
                                      </div>
                                      <div style={{marginTop:"20px",position:"relative",left:"2%"}}>
                                        <input
                                        className="form-check-input offer_include_check"
                                        type="checkbox"
                                        id="formCheck1"
                                        value={data.confirm_mail_status}
                                        checked={
                                          data.confirm_mail_status === true
                                            ? true
                                            : false
                                        }
                                        onChange={(e) =>
                                          handleToggle(
                                            e.target.checked,
                                            "confirm_mail_status",
                                            i,
                                          )
                                        }

                                      />
                                      <label style={{ marginLeft: "5px"}}>
                                       Confirmation Email
                                      </label>
                                      </div>
                                      <div style={{marginTop:"15px",position:"relative",left:"4%"}}>
                                       <label>Rate</label>
                                       {/* <div className="upsell_offer_rate">{data.rate}  */}
                                       <input type="text" className="upsell_offer_rate" name="rate"
                                         value={data.rate}
                                         onChange={(e) => handleRoomInfoChange('rate',i,e.target.value)}/>
                                       <select
                                          value={data.type}
                                          onChange={(e) => handleDropdownChange(e.target.value, i)}
                                          style={{border:"1px solid #d2d2d2",height:"30px",borderRadius:"3px"}}
                                        >
                                          <option value="currency">{currency.code}</option>
                                          <option value="percentage">%</option>
                                        </select>
                                        {/* {formerror1.map((err, ind) => {
                                                return (
                                                  <>
                                                    {ind === i &&
                                                    err &&
                                                    err.rate ? (
                                                      <label
                                                        style={{ color: "red" }}
                                                      >
                                                        {err.rate}
                                                      </label>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </>
                                                );
                                              })} */}
                                       </div>
                                      
                                      </div>
                                      <div style={{marginBottom:"2%"}}>
                                        <label>Promotion Title</label>
                                        <input type="text" className="upsell_promotion_title" name="promotion_title"
                                          value={data.promotion_title}
                                          onChange={(e) => handleRoomInfoChange('promotion_title',i,e.target.value)}/>
                                           {/* {formerror1.map((err, ind) => {
                                                return (
                                                  <>
                                                    {ind === i &&
                                                    err &&
                                                    err.promotion_title ? (
                                                      <label
                                                        style={{ color: "red" }}
                                                      >
                                                        {err.promotion_title}
                                                      </label>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </>
                                                );
                                              })} */}
                                      </div>
                                      </div>
                                     
                                    </>
                                  );
                                })}
                                </>
                                :tab==="board_upsell"?
                                <>
                               {boards.map((data, i) => {
                                  return (
                                    <>
                                      <div className="d-flex"
                                        key={i}
                                        style={{
                                          border: "1px solid #f1f1f1",
                                          marginTop: "2%",
                                          justifyContent:"space-between"
                                        }}
                                      >
                                        <div className="room-style-upselling text-center">
                                          <span>
                                            {data.label}
                                          </span>
                                        </div>
                                        <img
                                          src="/images/icon/upsell.jpeg"
                                          style={{
                                            height: "50px",
                                            marginTop: "20px",
                                          }}
                                        />
                                        <div
                                          className="room-style-upselling-new text-center">
                                          {selectedBoardUpsell[i]?.value ? (
                                            <>
                                              <span>
                                                {selectedBoardUpsell[i]?.label}
                                              </span>
                                              <span
                                                className="bx bxs-chevron-down"
                                                style={{
                                                  float: "right",
                                                  marginTop: "5px",
                                                  cursor: "pointer",
                                                }}
                                                onClick={() =>
                                                  tog_animationFlip1(i)
                                                }
                                              ></span>
                                            </>
                                          ) : (
                                            <span>
                                              <Link
                                                className="extra-rate-add"
                                                to="#"
                                                onClick={() =>
                                                  tog_animationFlip1(i)
                                                }
                                              >
                                                +
                                              </Link>
                                            </span>
                                          )}
                                        </div>
                                        <div style={{marginTop:"20px"}}>
                                        <input
                                        className="form-check-input offer_include_check"
                                        type="checkbox"
                                        id="formCheck1"
                                        value={data.pre_stay_status}
                                        checked={
                                          data.pre_stay_status === true
                                            ? true
                                            : false
                                        }
                                        onChange={(e) =>
                                          handleToggleBoard(
                                            e.target.checked,
                                            "pre_stay_status",
                                            i,
                                          )
                                        }
                                      />
                                      <label style={{ marginLeft: "5px" }}>
                                        Pre-Stay
                                      </label>
                                      </div>
                                      <div style={{marginTop:"20px",position:"relative",left:"2%"}}>
                                        <input
                                        className="form-check-input offer_include_check"
                                        type="checkbox"
                                        id="formCheck1"
                                        value={data.confirm_mail_status}
                                        checked={
                                          data.confirm_mail_status === true
                                            ? true
                                            : false
                                        }
                                        onChange={(e) =>
                                          handleToggleBoard(
                                            e.target.checked,
                                            "confirm_mail_status",
                                            i,
                                          )
                                        }

                                      />
                                      <label style={{ marginLeft: "5px"}}>
                                       Confirmation Email
                                      </label>
                                      </div>
                                      <div style={{marginTop:"15px",position:"relative",left:"4%"}}>
                                       <label>Rate</label>
                                       <input type="text" className="upsell_offer_rate" name="rate"
                                         value={data.rate}
                                         onChange={(e) => handleRoomInfoChangeBoard('rate',i,e.target.value)}/>
                                          {/* {formerror2.map((err, ind) => {
                                                return (
                                                  <>
                                                    {ind === i &&
                                                    err &&
                                                    err.rate ? (
                                                      <label
                                                        style={{ color: "red" }}
                                                      >
                                                        {err.rate}
                                                      </label>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </>
                                                );
                                              })} */}
                                       </div>
                                      </div>
                                    </>
                                  );
                                })}
                                </>
                                :
                                <>
                                <div className="d-flex">
                                  <div className="col-md-5"></div>
                                  <div className="col-md-4"><span className="addon_upsell_head">Confirmation Add-ons</span></div>
                                  <div className="col-md-4"><span className="addon_upsell_head">Pre-stay Add-ons</span></div> 
                                </div>
                               {rooms.map((data, i) => {
                                  return (
                                    <>
                                      <div className="d-flex"
                                        key={i}
                                        style={{
                                          border: "1px solid #f1f1f1",
                                          marginTop: "2%",
                                          justifyContent:"space-between"
                                        }}
                                      >
                                        <div className="room-style-upselling">
                                          {data.room_photo ? (
                                            <div
                                              className=""
                                              style={{ float: "left" }}
                                            >
                                              <img
                                                src={`${Commonimagepath.apiurl}${data.room_photo}`}
                                                alt=""
                                                className="position-absolute"
                                                style={{
                                                  width: "35px",
                                                  height: "30px",
                                                  bottom: "10px",
                                                }}
                                              />
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                          <span style={{ paddingLeft: "15%" }}>
                                            {data.label}
                                          </span>
                                        </div>
                                        <img
                                          src="/images/icon/upsell.jpeg"
                                          style={{
                                            height: "50px",
                                            marginTop: "20px",
                                          }}
                                        />
                                        <div
                                          className="room-style-upselling-new text-center">
                                          {selectedExtraUpsell[i]?.length ? (
                                            <>
                                              <div>
                                                <span>
                                                  {selectedExtraUpsell[i].map(extra => extra.label).join(', ')}
                                                </span>
                                                <span
                                                  className="bx bxs-chevron-down"
                                                  style={{
                                                    float: "right",
                                                    marginTop: "5px",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() => tog_animationFlip2(i)}
                                                ></span>
                                              </div>
                                            </>
                                          ) : (
                                            <span>
                                              <Link
                                                className="extra-rate-add"
                                                to="#"
                                                onClick={() => tog_animationFlip2(i)}
                                              >
                                                +
                                              </Link>
                                            </span>
                                          )}
                                        </div>

                                        <div
                                          className="room-style-upselling-new text-center">
                                          {selectedExtraUpsell1[i]?.length ? (
                                            <>
                                              <div>
                                                <span>
                                                  {selectedExtraUpsell1[i].map(extra => extra.label).join(', ')}
                                                </span>
                                                <span
                                                  className="bx bxs-chevron-down"
                                                  style={{
                                                    float: "right",
                                                    marginTop: "5px",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() => tog_animationFlip3(i)}
                                                ></span>
                                              </div>
                                            </>
                                          ) : (
                                            <span>
                                              <Link
                                                className="extra-rate-add"
                                                to="#"
                                                onClick={() => tog_animationFlip3(i)}
                                              >
                                                +
                                              </Link>
                                            </span>
                                          )}
                                        </div>
                                      </div>
                              
                                    </>
                                  );
                                })}
                                </>
                                 }

                                <div
                                  style={{
                                    border: "1px solid #f1f1f1",
                                    marginTop: "2%",
                                  }}
                                >
                                  <h5
                                    style={{
                                      marginTop: "15px",
                                      marginLeft: "5%",
                                    }}
                                  >
                                    EMAIL SENDING CRITERIA
                                  </h5>
                                  <div style={{ marginLeft: "5%" }}>
                                    <div
                                      className="col-lg-8"
                                      style={{ marginTop: "20px" }}
                                    >
                                      <div className="mb-3">
                                        <div className="input-group">
                                          <label
                                            className="input-group-text input-joint-color"
                                            id="inputGroup-sizing-default"
                                          >
                                            Send based on the cancellation
                                            penalty criteria{" "}
                                            <span className="form-validation-required">
                                              *
                                            </span>
                                          </label>
                                          <select
                                            className="form-select"
                                            id="idPayment"
                                            value={list?.cancel_penalty_type}
                                            onChange={(e) =>
                                              handleConditionChange(
                                                "cancel_penalty_type",
                                                e.target.value
                                              )
                                            }
                                          >
                                            <option value="">Select...</option>
                                            <option value="with_penalty">
                                              Only to reservations with penalty
                                              (deadline exceeded)
                                            </option>
                                            <option value="without_penalty">
                                              Only to reservations WITHOUT a
                                              penalty (deadline not exceeded)
                                            </option>
                                            <option value="both">All</option>
                                          </select>
                                        </div>
                                      </div>
                                      <label style={{ color: "red" }}>
                                        {formerror.cancel_penalty_type}
                                      </label>
                                    </div>
                                    <div className="col-lg-8">
                                      <div className="mb-3">
                                        <div className="input-group">
                                          <label
                                            className="input-group-text input-joint-color"
                                            id="inputGroup-sizing-default"
                                            style={{ width: "40%" }}
                                          >
                                            Send based on prepayment conditions
                                            <span className="form-validation-required">
                                              *
                                            </span>
                                            <span
                                              className="info-tag"
                                              style={{ left: "5px" }}
                                            >
                                              i
                                              <span className="tooltiptext">
                                                at the time of reservation
                                              </span>
                                            </span>
                                          </label>
                                          <select
                                            className="form-select"
                                            id="idPayment"
                                            value={list?.prepayment_type}
                                            onChange={(e) =>
                                              handleConditionChange(
                                                "prepayment_type",
                                                e.target.value
                                              )
                                            }
                                          >
                                            <option value="">Select...</option>
                                            <option value="with_prepayment">
                                              BAR
                                            </option>
                                            <option value="without_prepayment">
                                              NR
                                            </option>
                                            <option value="both">All</option>
                                          </select>
                                        </div>
                                      </div>
                                      <label style={{ color: "red" }}>
                                        {formerror.prepayment_type}
                                      </label>
                                    </div>
                                    <div className="col-lg-8">
                                      <div className="mb-3">
                                        <div className="input-group">
                                          <label
                                            className="input-group-text input-joint-color"
                                            id="inputGroup-sizing-default"
                                            style={{ width: "40%" }}
                                          >
                                            Send based on Club membership
                                            criteria
                                            <span className="form-validation-required">
                                              *
                                            </span>
                                          </label>
                                          <select
                                            className="form-select"
                                            id="idPayment"
                                            value={list?.member_type}
                                            onChange={(e) =>
                                              handleConditionChange(
                                                "member_type",
                                                e.target.value
                                              )
                                            }
                                          >
                                            <option value="">Select...</option>
                                            <option value="club_member">
                                              Only to member reservations
                                            </option>
                                            <option value="non_member">
                                              Only to non-member reservations
                                            </option>
                                            <option value="both">All</option>
                                          </select>
                                        </div>
                                      </div>
                                      <label style={{ color: "red" }}>
                                        {formerror.member_type}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="padding-20"></div>
                          </form>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {toastmodal === true ? (
          <Toastmodal
            setToast={setToastmodal}
            message={toastmessage}
            type={toasttype}
            large={toastlarge}
          />
        ) : (
          ""
        )}
        <Modal
          id="flipModal"
          className="new_modal_loyal"
          isOpen={modal_animationFlip}
          toggle={() => {
            tog_animationFlip();
          }}
          modalClassName="flip"
          centered
          style={{ fontFamily: "IBM Plex Sans,sans-serif" }}
        >
          <ModalBody
            className="modal-body text-center"
            style={{ height: "120px", width: "500px" }}
          >
            <div className="restr_policy_expand" style={{ marginTop: "10px" }}>
              <div className="col-lg-12">
                <div className="input-group">
                  <label
                    className="input-group-text input-joint-color"
                    id="inputGroup-sizing-default"
                  >
                    Upsell Rooms
                  </label>
                  <div className="col-md-8">
                    <Select
                      placeholder="Select..."
                      className="form-control multiselect_p-sale"
                      data-choices
                      name="choices-single-default"
                      id="choices-single-default"
                      value={selectedUpsell[loop]}
                      onChange={(val) => handleUpsellChange(val)}
                      options={upsell}
                    ></Select>
                  </div>
                </div>
              </div>
            </div>
            <button
              className="btn btn-link link-success fw-medium text-decoration-none"
              onClick={() => {
                tog_animationFlip();
              }}
              style={{ marginTop: "10px" }}
            >
              {" "}
              <i className="ri-close-line me-1 align-middle"></i>
              Close{" "}
            </button>
          </ModalBody>
        </Modal>

        <Modal
          id="flipModal"
          className="new_modal_loyal"
          isOpen={modal_animationFlip1}
          toggle={() => {
            tog_animationFlip1();
          }}
          modalClassName="flip"
          centered
          style={{ fontFamily: "IBM Plex Sans,sans-serif" }}
        >
          <ModalBody
            className="modal-body text-center"
            style={{ height: "120px", width: "500px" }}
          >
            <div className="restr_policy_expand" style={{ marginTop: "10px" }}>
              <div className="col-lg-12">
                <div className="input-group">
                  <label
                    className="input-group-text input-joint-color"
                    id="inputGroup-sizing-default"
                  >
                    Upsell Boards
                  </label>
                  <div className="col-md-8">
                    <Select
                      placeholder="Select..."
                      className="form-control multiselect_p-sale"
                      data-choices
                      name="choices-single-default"
                      id="choices-single-default"
                      value={selectedBoardUpsell[loopboard]}
                      onChange={(val) => handleUpsellBoardChange(val)}
                      options={upsellBoards}
                    ></Select>
                  </div>
                </div>
              </div>
            </div>
            <button
              className="btn btn-link link-success fw-medium text-decoration-none"
              onClick={() => {
                tog_animationFlip1();
              }}
              style={{ marginTop: "10px" }}
            >
              {" "}
              <i className="ri-close-line me-1 align-middle"></i>
              Close{" "}
            </button>
          </ModalBody>
        </Modal>

        <Modal
          id="flipModal"
          className="new_modal_loyal"
          isOpen={modal_animationFlip2}
          toggle={() => {
            tog_animationFlip2();
          }}
          modalClassName="flip"
          centered
          style={{ fontFamily: "IBM Plex Sans,sans-serif" }}
        >
          <ModalBody
            className="modal-body text-center"
            style={{ height: "120px", width: "500px" }}
          >
            <div className="restr_policy_expand" style={{ marginTop: "10px" }}>
              <div className="col-lg-12">
                <div className="input-group">
                  <label
                    className="input-group-text input-joint-color"
                    id="inputGroup-sizing-default"
                  >
                    Add-ons
                  </label>
                  <div className="col-md-8">
                  <MultiSelect
                  options={extras}
                  value={selectedExtraUpsell[loopextra] || []} 
                  onChange={handleUpsellChangeExtra}
                  labelledBy="Select"
                  selectionType="counter"
                  allowCustomValue={true}
                />
                  </div>
                </div>
              </div>
            </div>
            <button
              className="btn btn-link link-success fw-medium text-decoration-none"
              onClick={() => {
                tog_animationFlip2();
              }}
              style={{ marginTop: "10px" }}
            >
              {" "}
              <i className="ri-close-line me-1 align-middle"></i>
              Close{" "}
            </button>
          </ModalBody>
        </Modal>
        <Modal
          id="flipModal"
          className="new_modal_loyal"
          isOpen={modal_animationFlip3}
          toggle={() => {
            tog_animationFlip3();
          }}
          modalClassName="flip"
          centered
          style={{ fontFamily: "IBM Plex Sans,sans-serif" }}
        >
          <ModalBody
            className="modal-body text-center"
            style={{ height: "120px", width: "500px" }}
          >
            <div className="restr_policy_expand" style={{ marginTop: "10px" }}>
              <div className="col-lg-12">
                <div className="input-group">
                  <label
                    className="input-group-text input-joint-color"
                    id="inputGroup-sizing-default"
                  >
                    Add-ons
                  </label>
                  <div className="col-md-8">
                  <MultiSelect
                  options={extras}
                  value={selectedExtraUpsell1[loopextra1] || []} 
                  onChange={handleUpsellChangeExtra1}
                  labelledBy="Select"
                  selectionType="counter"
                  allowCustomValue={true}
                />
                  </div>
                </div>
              </div>
            </div>
            <button
              className="btn btn-link link-success fw-medium text-decoration-none"
              onClick={() => {
                tog_animationFlip3();
              }}
              style={{ marginTop: "10px" }}
            >
              {" "}
              <i className="ri-close-line me-1 align-middle"></i>
              Close{" "}
            </button>
          </ModalBody>
        </Modal>

        <Footer />
      </div>
    </div>
  );
}

export default Upselling;

import React, { useEffect, useState } from "react";
import Footer from "../../Common/Footer";
import { Link, useNavigate } from "react-router-dom";
import { useErrorBoundary } from "preact/hooks";
import Select from "react-select";
import { MultiSelect } from "react-multi-select-component";
import Config from "../../Config";
import Alerts from "../../Alerts";
import axios from "axios";
import Commonheader from "../../Common/Commonheader";

function Adminuser() {
  const [active, setActive] = useState("en");
  const [role, setRole] = useState([]);
  const [be_language, setBe_language] = useState([]);
  const [be_languagefull, setBe_languagefull] = useState([]);
  let navigate = useNavigate();

  const language = localStorage.getItem("be_language");
  const [alert, setAlert] = useState(false);
  const [alertmessage, setAlertmessage] = useState("");
  const [alerttype, setAlerttype] = useState("");
  const [formerror, setError] = useState({});

  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState("");
  const [adminuser, setAdminuser] = useState({
    user_name: "",
    surname: "",
    email: "",
    password: "",
    image: "",
    c_password: "",
  });
  const [country, setCountry] = useState([]);
  const [roledata, setRoledata] = useState("");
  const [selectedUsertype, setSelectedUsertype] = useState([]);
  const [selectedHotel, setSelectedHotel] = useState([]);
  const [allhotels, setAllhotels] = useState("");
  const [hotelnew, setHotelnew] = useState("");

  const [langdata, setLangdata] = useState("");
  const [countrydata, setCountrydata] = useState("");

  const usertype = [
    { value: "admin-user", label: "Admin" },
    { value: "vendor-user", label: "Vendor" },
  ];

  const getRole = async (e) => {
    if (language === null) {
      const res = await fetch(`${Config.apiurl}role-list`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      const data1 = await res.json();
      const data = data1.data;

      if (data1.status_code !== "200" || !data) {
        console.log("error");
      } else {
        setRole(data);
      }
    } else {
      const res = await fetch(`${Config.apiurl}role-list`, {
        method: "POST",
        body: JSON.stringify({
          language: language,
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      const data1 = await res.json();
      const data = data1.data;

      if (data1.status_code !== "200" || !data) {
        console.log("error");
      } else {
        setRole(data);
      }
    }
  };

  const getlanguage = async (e) => {
    const res = await fetch(`${Config.apiurl}optional-languages`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    const data = await res.json();
    if (data.status_code !== "200" || !data.data) {
      console.log("error");
    } else {
      setBe_language(data.data);
    }
  };

  const getfulllanguage = async (e) => {
    const res = await fetch(`${Config.apiurl}booking-engine-languages`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    const data = await res.json();
    if (data.status_code !== "200" || !data.data) {
      console.log("error");
    } else {
      setBe_languagefull(data.data);
    }
  };

  const getcountry = async (e) => {
    const res = await fetch(`${Config.apiurl}country-list`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    const data = await res.json();
    if (data.status_code !== "200" || !data.data) {
      console.log("error");
    } else {
      setCountry(data.data);
    }
  };

  const handleInput = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setAdminuser({ ...adminuser, [name]: value });
  };
  const handleInputdata = (roledata) => {
    setRoledata(roledata);
  };

  const handleInputlang = (langdata) => {
    setLangdata(langdata);
  };
  const handleInputcountry = (countrydata) => {
    setCountrydata(countrydata);
  };
  const [selectedMulti, setselectedMulti] = useState([]);
  function handleMulti(selectedMulti) {
    setselectedMulti(selectedMulti);
  }

  const [selectedMulti1, setselectedMulti1] = useState([]);
  function handleMulti1(selectedMulti1) {
    setselectedMulti1(selectedMulti1);
  }

  const getHotel = async (e) => {
    const res = await fetch(`${Config.apiurl}hotels`, {
      method: "GET",
    });
    const data1 = await res.json();
    const data = data1.data;

    if (data1.status_code !== "200" || !data) {
      console.log("error");
    } else {
      setHotelnew(data);
    }
  };


//   const validate = (values) => {
//     const errors = {};
//     const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
//     const regex1 =
//       /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[~`¿¡!#$%^&*€£@+÷=\-\[\]\\';,/{}|\\":<>\?\._])./;

//     if (!values.user_name) {
//       errors.user_name = "Name is required!";
//     }
//     if (!values.surname) {
//       errors.surname = "Surname is required!";
//     }
//     if (!values.email) {
//       errors.email = "Email is required!";
//     } else if (!regex.test(values.email)) {
//       errors.email = "This is not a valid email format!";
//     }
//     if (!values.password) {
//       errors.password = "Password is required!";
//     } else if (!regex1.test(values.password)) {
//       errors.password =
//         "Password must contain at least one number, one lowercase letter, one uppercase letter and one special character!";
//     } else if (values.password.length < 8) {
//       errors.password = "The password must be at least 8 characters!";
//     }
//     if (!values.c_password) {
//       errors.c_password = "Confirm password is required!";
//     } else if (values.password !== values.c_password) {
//       errors.c_password = "The provided passwords do not match!";
//     }
//     if (!values.image) {
//       errors.image = "Image is required!";
//     }
//     return errors;
//   };

//   const validaterole = (values) => {
//     const errors = {};
//     if (!values) {
//       errors.role = "Role is required!";
//     }

//     return errors;
//   };
//   const validatelang = (values) => {
//     const errors = {};
//     if (!values) {
//       errors.lang = "Language is required!";
//     }

//     return errors;
//   };
//   const validatecountry = (values) => {
//     const errors = {};
//     if (!values) {
//       errors.country = "Country is required!";
//     }

//     return errors;
//   };
//   const validateUsertype = (values) => {
//     const errors = {};
//     if (!values) {
//       errors.selectedUsertype = "User Type is required!";
//     }

//     return errors;
//   };

//   if(selectedUsertype.value==="vendor"){
//   const validateHotel = (values) => {
//     const errors = {};
//     if (values.length === 0) {
//       errors.selectedHotel = "Hotel is required!";
//     }

//     return errors;
//   };
// }

//   const validateselect = (values) => {
//     const errors = {};
//     if (values.length === 0) {
//       errors.langselect = "Language is required!";
//     }

//     return errors;
//   };
//   const validatestatus = (values) => {
//     const errors = {};
//     if (!values) {
//       errors.status = "Status is required!";
//     }

//     return errors;
//   };
//   const PostData = async (e) => {
//     e.preventDefault();
//     console.log("ji");
//     let per = [];
//     let lang = [];

   
//     const ress = validate(adminuser);
//     const ressrole = validaterole(roledata);
//     const resslang = validatelang(langdata);
//     const resscountry = validatecountry(countrydata);
//     const ressselect = validateselect(selectedMulti);
//     const ressstatus = validatestatus(status);

//     setError(ress);
//     setError1(ressrole);
//     setError2(resslang);
//     setError3(resscountry);
//     setError4(ressselect);
//     setError5(ressstatus);

//     if (
//       !ress.user_name &&
//       !ress.surname &&
//       !ress.email &&
//       !ress.password &&
//       !ress.c_password &&
//       !ress.image &&
//       !ressrole.role &&
//       !ressstatus.status &&
//       !resslang.lang &&
//       !resscountry.country &&
//       !ressselect.langselect
//     ) {
//       const formData = new FormData();
//       formData.append("en_name", adminuser.user_name);
//       formData.append("en_surname", adminuser.surname);
//       formData.append("password", adminuser.password);
//       formData.append("email", adminuser.email);
//       formData.append("password_confirmation", adminuser.c_password);
//       formData.append("is_active", status);

//       formData.append("profile_image", adminuser.image);
//       formData.append("role", roledata.value);
//       formData.append("default_language", langdata.value);
//       for (let i = 0; i < selectedMulti.length; i++) {
//         formData.append(`optional_language[${i}]`, selectedMulti[i].value);
//       }
//       // formData.append("optional_language", per);
//       formData.append("country", countrydata.value);
//       for (let i = 0; i < selectedMulti1.length; i++) {
//         if (selectedMulti1[i].name) {
//           if (selectedMulti1[i].code !== "en") {
//             formData.append(
//               `multi_lang[${i}][lang_code]`,
//               selectedMulti1[i].code
//             );
//             formData.append(
//               `multi_lang[${i}][fields][name]`,
//               selectedMulti1[i].name
//             );
//             formData.append(
//               `multi_lang[${i}][fields][surname]`,
//               selectedMulti1[i].surname
//             );
//           }
//         }
//       }
//       // formData.append("multi_lang", lang);
//       axios
//         .post(`${Config.apiurl}admin/create-admin-user`, formData, {
//           headers: {
//             Authorization: `Bearer ${localStorage.getItem("token")}`,
//           },
//         })
//         .then((res) => {
//           // const res = await fetch(`${Config.apiurl}admin/create-admin-user`, {
//           //   method: "POST",
//           //   body: JSON.stringify({
//           //     en_name: adminuser.user_name,
//           //     en_surname:adminuser.surname,
//           //     password:adminuser.password,
//           //     email:adminuser.email,
//           //     password_confirmation:adminuser.c_password,
//           //     is_active:status,
//           //     role:roledata.value,
//           //     default_language:langdata.value,
//           //     optional_language:per,
//           //     country:countrydata.value,
//           //     multi_lang:lang
//           //   }),
//           //   headers: {
//           //     Authorization: `Bearer ${localStorage.getItem("token")}`,
//           //     "Content-type": "application/json; charset=UTF-8",
//           //   },
//           // });

//           // const data = await res.json();

//           if (res.message !== "Admin User Created Successfully" || !res) {
//             setAlert(true);
//             setAlertmessage(res.message);
//             setOpen(true);
//             setAlerttype("error");
//           } else {
//             setAlert(true);
//             setAlertmessage(res.message);
//             setOpen(true);
//             setAlerttype("success");
//             Reset();
//             setTimeout(() => navigate("/admin_user_listing"), 3000);
//           }
//         });
//     }
//   };

  const PostData = async (e) => {
    e.preventDefault();
    let isFormValid = true;
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const regex1 = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[~`¿¡!#$%^&*€£@+÷=\-\[\]\\';,/{}|\\":<>\?\._])./;
    const errors = {};

    if (!adminuser.user_name) {
      isFormValid = false;
      errors.user_name = "Name is required!";
    }
    if (!adminuser.surname) {
      isFormValid = false;
      errors.surname = "Surname is required!";
    }
    if (!adminuser.email) {
      isFormValid = false;
      errors.email = "Email is required!";
    } else if (!regex.test(adminuser.email)) {
      isFormValid = false;
      errors.email = "This is not a valid email format!";
    }
    if (!adminuser.password) {
      isFormValid = false;
      errors.password = "Password is required!";
    }
    else if (!regex1.test(adminuser.password)) {
      isFormValid = false;
      errors.password = "Password must contain at least one number, one lowercase letter, one uppercase letter and one special character!";
    }
    else if (adminuser.password.length < 8) {
      isFormValid = false;
      errors.password = "The password must be at least 8 characters!";
    }
    if (!adminuser.c_password) {
      isFormValid = false;
      errors.c_password = "Confirm password is required!";
    } else if (adminuser.password !== adminuser.c_password) {
      isFormValid = false;
      errors.c_password = "The provided passwords do not match!";
    }
    if (!adminuser.image) {
      isFormValid = false;
      errors.image = "Profile Image is required!";
    }
    else{
      if(
        adminuser.image.type === "image/jpeg" ||
        adminuser.image.type === "image/jpg" ||
        adminuser.image.type === "image/png" ||
        adminuser.image.type === "image/webp" 
      ){
      console.log("");
    } else {  
      isFormValid = false;
      errors.image = "Only support jpg, jpeg, png, webp image!";
    }
  }
    if (roledata.length===0) {
      isFormValid = false;
      errors.roledata = "Role is required!";
    }
    if (selectedUsertype.length===0) {
      isFormValid = false;
      errors.selectedUsertype = "User Type is required!";
    }
    if(selectedUsertype.value==="vendor"){
    if (selectedHotel.length===0) {
      isFormValid = false;
      errors.selectedHotel = "Hotel is required!";
    }
  }
    if (langdata.length===0) {
      isFormValid = false;
      errors.langdata = "Default language is required!";
    }
    if (selectedMulti.length===0) {
      isFormValid = false;
      errors.selectedMulti = "Optional language is required!";
    }
    if (countrydata.length===0) {
      isFormValid = false;
      errors.countrydata = "Country is required!";
    }
    if (status.length===0) {
      isFormValid = false;
      errors.status = "Status is required!";
    }
    setError(errors);

    if (isFormValid===true) {
      const formData = new FormData();
      formData.append("en_name", adminuser.user_name);
      formData.append("en_surname", adminuser.surname);
      formData.append("password", adminuser.password);
      formData.append("email", adminuser.email);
      formData.append("password_confirmation", adminuser.c_password);
      formData.append("is_active", status);

      formData.append("profile_image", adminuser.image);
      formData.append("role", roledata.value);
      formData.append("user_type", selectedUsertype.value);
      formData.append("default_language", langdata.value);
      if(selectedUsertype.value==="vendor-user"){
      for (let i = 0; i < selectedHotel.length; i++) {
        formData.append(`hotels[${i}]`, selectedHotel[i].value);
      }
    }
      for (let i = 0; i < selectedMulti.length; i++) {
        formData.append(`optional_language[${i}]`, selectedMulti[i].value);
      }
      formData.append("country", countrydata.value);
      for (let i = 0; i < selectedMulti1.length; i++) {
        if (selectedMulti1[i].name) {
          if (selectedMulti1[i].code !== "en") {
            formData.append(
              `multi_lang[${i}][lang_code]`,
              selectedMulti1[i].code
            );
            formData.append(
              `multi_lang[${i}][fields][name]`,
              selectedMulti1[i].name
            );
            formData.append(
              `multi_lang[${i}][fields][surname]`,
              selectedMulti1[i].surname
            );
          }
        }
      }
      axios
        .post(`${Config.apiurl}admin/create-admin-user`, formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          if (res.status_code !==200 || !res) {
            setAlert(true);
            setAlertmessage(res.message);
            setOpen(true);
            setAlerttype("error");
          } else {
            setAlert(true);
            setAlertmessage(res.message);
            setOpen(true);
            setAlerttype("success");
            Reset();
            setTimeout(() => navigate("/admin_user_listing"), 3000);
          }
        });
    }
  };

  const onFileChange = (e) => {
    console.log(e);
    let name = e.target.name;
    setAdminuser({ ...adminuser, [name]: e.target.files[0] });
  };

  function Reset() {
    setAdminuser({
      user_name: "",
      surname: "",
      email: "",
      password: "",
      c_password: "",
    });
    setRoledata("");
    setLangdata("");
    setCountrydata("");
    setselectedMulti([]);
    setselectedMulti1([]);
    setStatus("");
  }

  useEffect(() => {
    if (hotelnew) {
      const matchedHotels = hotelnew.map((hotel) => ({
        value: hotel.hotel_id,
        label: hotel.hotel_name,
      }));
      
      setAllhotels(matchedHotels);
    }
  }, [hotelnew.length]);

  useEffect(() => {
    getRole();
    getlanguage();
    getcountry();
    getfulllanguage();
    getHotel();

  }, [language]);

  return (
    <div id="layout-wrapper">
      <Commonheader />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="db-page-title">ADMIN USER</h4>
                  {/* <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Dashboard</Link>
                      </li>
                      <li className="breadcrumb-item ">
                        <Link to="#">DR Super Admin</Link>
                      </li>
                      <li className="breadcrumb-item ">
                        <Link to="/admin_user_listing">Admin Users</Link>
                      </li>
                    </ol>
                  </div> */}
                </div>
              </div>
            </div>
            {alert ? (
              <Alerts type={alerttype} message={alertmessage} open={open} />
            ) : (
              ""
            )}
            <div className="row project-wrapper">
              <div className="row dashboard-top-tab-style">
                <div className="col-xxl-12 dashboard-main-style">
                  <div className="card">
                    <div className="card-header align-items-center d-flex res-header">
                      <h4 className="card-title mb-0 flex-grow-1">
                        Create New Admin User
                      </h4>
                      <div className="flex-shrink-0">
                        <Link
                          type="button"
                          className="btn db-other-button btn-currency-edt"
                          to="/admin_user_listing"
                          style={{ marginRight: "5px" }}
                        >
                          Admin User
                        </Link>
                        <button
                          type="button"
                          className="btn db-cancel-button"
                          data-bs-toggle="offcanvas"
                          to=""
                          onClick={Reset}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="btn db-save-button"
                          data-bs-toggle="modal"
                          onClick={PostData}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="live-preview">
                        <form action="#">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-translation-style">
                                <ul>
                                  <li
                                    className={active === "en" ? "active" : ""}
                                  >
                                    <Link
                                      to="#"
                                      onClick={() => setActive("en")}
                                      className={
                                        active === "en" ? "active" : ""
                                      }
                                    >
                                      EN
                                    </Link>
                                  </li>
                                  <li
                                    className={
                                      active === "trans" ? "active" : ""
                                    }
                                  >
                                    <Link
                                      to="#"
                                      onClick={() => setActive("trans")}
                                      className={
                                        active === "trans" ? "active" : ""
                                      }
                                    >
                                      Translations
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            {active === "en" ? (
                              <>
                                <div className="col-md-6">
                                  <div className="mb-3">
                                    <label
                                      for="firstNameinput"
                                      className="form-label"
                                    >
                                      Name
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder=""
                                      value={adminuser.user_name}
                                      name="user_name"
                                      onChange={handleInput}
                                    />
                                    <label
                                      style={{ color: "red", marginTop: "3px" }}
                                    >
                                      {formerror.user_name}
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="mb-3">
                                    <label
                                      for="firstNameinput"
                                      className="form-label"
                                    >
                                      Surname
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder=""
                                      value={adminuser.surname}
                                      name="surname"
                                      onChange={handleInput}
                                    />
                                    <label
                                      style={{ color: "red", marginTop: "3px" }}
                                    >
                                      {formerror.surname}
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="mb-3">
                                    <label
                                      for="firstNameinput"
                                      className="form-label"
                                    >
                                      Email
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                    </label>
                                    <input
                                      type="email"
                                      className="form-control"
                                      placeholder=""
                                      value={adminuser.email}
                                      name="email"
                                      onChange={handleInput}
                                    />
                                    <label
                                      style={{ color: "red", marginTop: "3px" }}
                                    >
                                      {formerror.email}
                                    </label>
                                  </div>
                                </div>

                                <div className="col-md-6">
                                  <div className="mb-3">
                                    <label
                                      for="firstNameinput"
                                      className="form-label"
                                    >
                                      Password
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                    </label>
                                    <input
                                      type="password"
                                      className="form-control"
                                      placeholder=""
                                      value={adminuser.password}
                                      name="password"
                                      onChange={handleInput}
                                    />
                                    <label
                                      style={{ color: "red", marginTop: "3px" }}
                                    >
                                      {formerror.password}
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="mb-3">
                                    <label
                                      for="firstNameinput"
                                      className="form-label"
                                    >
                                      Confirm Password
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                    </label>
                                    <input
                                      type="password"
                                      className="form-control"
                                      placeholder=""
                                      value={adminuser.c_password}
                                      name="c_password"
                                      onChange={handleInput}
                                    />
                                    <label
                                      style={{ color: "red", marginTop: "3px" }}
                                    >
                                      {formerror.c_password}
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="mb-3">
                                    <label
                                      for="firstNameinput"
                                      className="form-label"
                                    >
                                      Profile Image
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                    </label>
                                    <input
                                      type="file"
                                      className="form-control"
                                      placeholder=""
                                      id="lastNameinput"
                                      name="image"
                                      onChange={onFileChange}
                                      accept=".jpg, .jpeg, .webp, .png"
                                    />
                                    <label
                                      style={{ color: "red", marginTop: "3px" }}
                                    >
                                      {formerror.image}
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="mb-3">
                                    <label
                                      for="firstNameinput"
                                      className="form-label"
                                    >
                                      Role
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                    </label>
                                    <Select
                                      className="form-select1"
                                      data-trigger
                                      options={role}
                                      value={roledata}
                                      onChange={handleInputdata}
                                    ></Select>

                                    <label
                                      style={{ color: "red", marginTop: "3px" }}
                                    >
                                      {formerror.roledata}
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="mb-3">
                                    <label
                                      for="firstNameinput"
                                      className="form-label"
                                    >
                                      User Type
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                    </label>
                                    <Select
                                      className="form-select1"
                                      data-trigger
                                      options={usertype}
                                      value={selectedUsertype}
                                      onChange={(e)=>setSelectedUsertype(e)}
                                    ></Select>

                                    <label
                                      style={{ color: "red", marginTop: "3px" }}
                                    >
                                      {formerror.selectedUsertype}
                                    </label>
                                  </div>
                                </div>
                                {selectedUsertype.value==="vendor-user"?
                                <div className="col-md-4">
                                  <div className="mb-3">
                                    <label
                                      for="firstNameinput"
                                      className="form-label"
                                    >
                                      Hotels
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                    </label>
                                    <MultiSelect
                                      options={allhotels}
                                      value={selectedHotel}
                                      onChange={setSelectedHotel}
                                      labelledBy="Select"
                                      selectionType="counter"
                                      closeOnChangedValue={false}
                                      aria-expanded="true"
                                    />

                                    <label
                                      style={{ color: "red", marginTop: "3px" }}
                                    >
                                      {formerror.selectedHotel}
                                    </label>
                                  </div>
                                </div>
                                :""}
                                <div className="col-md-4">
                                  <div className="mb-3">
                                    <label
                                      for="firstNameinput"
                                      className="form-label"
                                    >
                                      Default Language
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                    </label>
                                    <Select
                                      id="ForminputState"
                                      className="form-select1"
                                      options={be_languagefull}
                                      value={langdata}
                                      onChange={handleInputlang}
                                    ></Select>
                                    <label
                                      style={{ color: "red", marginTop: "3px" }}
                                    >
                                      {formerror.langdata}
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="mb-3">
                                    <label
                                      for="firstNameinput"
                                      className="form-label"
                                    >
                                      Optional Language
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                    </label>
                                    <MultiSelect
                                      options={be_languagefull}
                                      value={selectedMulti}
                                      onChange={setselectedMulti}
                                      labelledBy="Select"
                                      selectionType="counter"
                                      closeOnChangedValue={false}
                                      aria-expanded="true"
                                    />
                                    <label
                                      style={{ color: "red", marginTop: "3px" }}
                                    >
                                      {formerror.selectedMulti}
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="mb-3">
                                    <label className="form-label">
                                      Country{" "}
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                    </label>
                                    <Select
                                      className="form-control"
                                      data-choices
                                      name="choices-single-default"
                                      id="choices-single-default"
                                      options={country}
                                      value={countrydata}
                                      onChange={handleInputcountry}
                                    ></Select>

                                    <label
                                      style={{ color: "red", marginTop: "3px" }}
                                    >
                                      {formerror.countrydata}
                                    </label>
                                  </div>
                                </div>

                                <div className="col-md-4">
                                  <div className="mb-3">
                                    <label
                                      for="firstNameinput"
                                      className="form-label"
                                    >
                                      Status{" "}
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                    </label>
                                    <select
                                      id="ForminputState"
                                      className="form-select"
                                      value={status}
                                      onChange={(e) =>
                                        setStatus(e.target.value)
                                      }
                                    >
                                      <option selected>Choose...</option>
                                      <option value="1">Active</option>
                                      <option value="0">Inactive</option>
                                    </select>
                                    <label style={{ color: "red" }}>
                                      {formerror.status}
                                    </label>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="form-translation-choose-lang">
                                  <span
                                    for="firstNameinput"
                                    className="form-label db-lang-choose"
                                  >
                                    Choose Language:{" "}
                                  </span>
                                  <div className="lang-choose-select-option">
                                    <MultiSelect
                                      options={be_language}
                                      value={selectedMulti1}
                                      onChange={setselectedMulti1}
                                      labelledBy="Select"
                                      selectionType="counter"
                                      closeOnChangedValue={false}
                                      aria-expanded="true"
                                    />
                                  </div>
                                </div>
                                <div className="appendmore"></div>
                                {selectedMulti1.length > 0
                                  ? selectedMulti1.map((element, index) => {
                                      return (
                                        <div
                                          key={index}
                                          class="col-md-12 translation-fill-section"
                                          id="lang_ +val"
                                          data-value="1"
                                        >
                                          <div class="translation-selected-lang">
                                            <p>
                                              <span class="translation-selected-lang-head">
                                                {element.code}
                                              </span>
                                            </p>
                                          </div>
                                          <div className="row">
                                            <div class="col-md-6">
                                              <div class="mb-3">
                                                <label
                                                  for="firstNameinput"
                                                  class="form-label"
                                                >
                                                  Name
                                                  <span class="form-validation-required">
                                                    *
                                                  </span>
                                                </label>
                                                <input
                                                  type="text"
                                                  class="form-control"
                                                  placeholder=""
                                                  value={
                                                    selectedMulti1[index].name
                                                  }
                                                  onChange={(e) =>
                                                    (selectedMulti1[
                                                      index
                                                    ].name = e.target.value)
                                                  }
                                                />
                                              </div>
                                            </div>
                                            <div class="col-md-6">
                                              <div class="mb-3">
                                                <label
                                                  for="firstNameinput"
                                                  class="form-label"
                                                >
                                                  Surname
                                                  <span class="form-validation-required">
                                                    *
                                                  </span>
                                                </label>
                                                <input
                                                  type="text"
                                                  class="form-control"
                                                  placeholder=""
                                                  value={
                                                    selectedMulti1[index]
                                                      .surname
                                                  }
                                                  onChange={(e) =>
                                                    (selectedMulti1[
                                                      index
                                                    ].surname = e.target.value)
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })
                                  : ""}
                              </>
                            )}
                          </div>

                          <div className="padding-20"></div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default Adminuser;
